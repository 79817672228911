<template>
    <div class="chart-detail-page">
        <div class="wid1200">
            <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        </div>

        <div class="wid1200 chart-content">
            <div class="left-chart-wrap">
                <img class="table-img" v-if="chartObj.img" :src="chartObj.img" alt="" />
                <img class="table-img" v-else alt="" />
                <div class="chart-info-wrap">
                    <div class="chart-bg"></div>
                    <div class="chart-info">
                        <p><span>图表标题：</span> <span v-html="chartObj.title"></span></p>
                        <p><span>所属书刊：</span>
                            <router-link :to="{path: '/collectionDetail/c', query:{id: chartObj.bookId, pN: encodeURI(pageName)}}">
                                {{chartObj.bookTitle}}</router-link>
                        </p>
                        <p><span>所属文章：</span>
                            <router-link
                                :to="{path: '/aD/a', query:{id: chartObj.articleId, pN: encodeURI(pageName.slice(0, 13))}}">
                                {{chartObj.articleTitle}}</router-link>
                        </p>
                        <!-- title: chartObj.articleTitle,  -->
                        <p><span>在线日期：</span>{{chartObj.createDate}}</p>
                    </div>
                </div>
                <div class="chart-main-wrap">
                    <h5 class="chart-title" v-html="chartObj.title"></h5>
                    <p class="chart-main" v-html="chartObj.fullContent">

                    </p>
                    <p class="chart-btn" v-if="!chartObj.buy">
                        <router-link :to="{path: '/aD/a', query:{id: chartObj.articleId, pN: encodeURI(pageName.slice(0, 13))}}">
                            查看更多</router-link>
                    </p>
                    <!-- title: chartObj.articleTitle,  -->
                </div>

            </div>
            <div class="right-download-w">
                <div class="right-download-wrap">
                    <div class="com-title">
                        <div>
                            <img class="title-img" src="../assets/img/download.png" alt="" />
                            <h5>下载排行</h5>
                        </div>
                    </div>
                    <ul class="right-download" style="padding-top: 20px;">
                        <!-- <li class="first-download">
                            <div>
                                <img src="../assets/img/book.png" alt="" />
                            </div>
                            <div>
                                <h5 class="right-title" v-if="hotList && hotList[0] && hotList[0].title">
                                    {{hotList[0].title}}
                                </h5>
                                <p>近代史学刊》是华中师范大学中国近代史研究...</p>
                            </div>
                        </li> -->
                        <li class="download-li" v-for="(item, index) in hotList" :key="index" :id="item.id"
                            v-if="index<10" @click="goHot(item)" style="cursor: pointer;">
                            <!-- <img src="../assets/img/lock.png" alt="" /> -->
                            {{item.searchTitle ? item.searchTitle : item.title}}
                        </li>
                        <!-- <li class="download-li">
                            <img src="../assets/img/lock.png" alt="" />珠海海洋生态文化建设思考
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div id="mjs_tip" class="tip" style="position: absolute; left: 0; top: 0; display: none;"></div>
    </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'

export default {
    components: { BreadCrumb },
    data() {
        return {
            pN: '',
            pageName: '图表详情页',
            chartObj: '',
            hotList: []
        }
    },
    mounted() {
        this.$nextTick(function() {
            var notes = document.getElementsByClassName('note1')
            for (var i = 0; i < notes.length; i++) {
                notes[i].innerHTML = '<font style="color:red">' + (i + 1) + '</font>'
            }
        })
    },
    methods: {
        getData() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/photo/detail?id=' + this.$route.query.id
                // url: '/admin/api/research/detail?resourceId='+this.$route.query.id
            }).then(res => {
                if (res.data.code == 0) {
                    _this.chartObj = res.data.data
                    _this.pageName = _this.chartObj.title

                    _this.$nextTick(function() {
                        // chart-main-wrap
                        // document.getElementsByClassName('note1')

                        let notes = document.querySelectorAll(".chart-main-wrap font[class='note1']")
                        // console.log('notes', document.getElementsByClassName('note1'),notes)

                        for (var i = 0; i < notes.length; i++) {
                            notes[i].innerHTML = '<font style="color:red">' + (i + 1) + '</font>'
                        }
                        window.tip = {
                            Tips: function(ele) {
                                if (typeof ele == 'object') return ele
                                else if (typeof ele == 'string' || typeof ele == 'number') return document.getElementById(ele.toString())
                                return null
                            },
                            mousePos: function(e) {
                                var x, y
                                var e = e || window.event
                                return { x: e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft, y: e.clientY + document.body.scrollTop + document.documentElement.scrollTop }
                            },
                            start: function(obj) {
                                var self = this
                                var t = self.Tips('mjs_tip')
                                obj.onmousemove = function(e) {
                                    var mouse = self.mousePos(e)
                                    var toLeft = mouse.x + 10
                                    var toTop = mouse.y + 10
                                    t.style.left = toLeft + 'px'
                                    t.style.top = toTop + 'px'
                                    t.innerHTML = '注：'
                                    var str
                                    str = obj.getAttribute('tips')

                                    t.appendChild(document.createElement('div')).innerHTML = str
                                    t.style.display = ''
                                    var overTop = toTop + t.clientHeight - $(window).height() - $(window).scrollTop()
                                    var overLeft = toLeft + t.clientWidth - $(window).width() - $(window).scrollLeft()

                                    if (overTop > 0) {
                                        toTop = toTop - overTop - 2
                                        t.style.top = toTop + 'px'
                                    }
                                    if (overLeft > 0) {
                                        toLeft = toLeft - overLeft - 2
                                        t.style.left = toLeft + 'px'
                                    }
                                }
                                obj.onmouseout = function() {
                                    t.style.display = 'none'
                                }
                            }
                        }
                        // },500)
                    })
                }
            })
        },
        goHot(item) {
            this.$router.push({ path: '/aD/a', query: { id: item.id, pN: encodeURI(this.pageName) } })
        },
        getHotList() {
            var _this = this
            _this.$http.get('/admin/api/article/hot').then(res => {
                if (res.data.code == 0) {
                    _this.hotList = res.data.data
                }
            })
        }
    },
    created() {
        if (this.$route.query.pN) {
            this.pN = decodeURI(this.$route.query.pN)
        } else {
            this.pN = ''
        }
        this.getData()
        this.getHotList()
    }
}
</script>


<style lang="scss" scoped>
.chart-main >>> p {
  line-height: 28px;
  text-indent: 2em;
}
.chart-main >>> img {
  max-width: 800px;
  margin: 0 auto;
  height: auto;
}
.chart-main >>> p .role3 {
  /* 4补字 3行内 */
  display: inline-block;
  max-height: 18px;
}
.chart-main >>> p .role4 {
  display: inline-block;
  width: 13px !important;
  height: 13px !important;
}

.chart-detail-page {
    // background: blue;

    .chart-content {
        display: flex;
        justify-content: space-between;
        .left-chart-wrap {
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            padding: 0 22px;
            margin-top: 24px;
            margin-bottom: 34px;
            img.table-img {
                width: 807px;
                // height: 150px;
                margin: 22px auto 22px;
                border: 1px solid #e6e6e6;
            }
            .chart-info-wrap {
                position: relative;
                z-index: 0;
                .chart-bg {
                    position: absolute;
                    left: 9px;
                    top: 9px;
                    z-index: -1;
                    width: 798px;
                    height: 165px;
                    background: #e5e5e5;
                    opacity: 0.92;
                    border-radius: 2px;
                }
                .chart-info {
                    width: 798px;
                    height: 165px;
                    background: #dbbc85;
                    font-size: 16px;
                    padding-left: 20px;
                    box-sizing: border-box;
                    padding-top: 14px;
                    margin-bottom: 42px;
                    p {
                        color: #fff;
                        line-height: 35px;
                        span:first-child {
                            font-weight: bold;
                        }
                        a {
                            color: #fff;
                        }
                    }
                }
            }
            .chart-main-wrap {
                text-align: center;
                .chart-title {
                    text-align: center;

                    font-size: 22px;
                    color: #333;
                }
                .chart-main {
                    width: 816px;
                    text-align: left;
                    margin: 30px auto 24px;
                    font-size: 14px;
                    color: #666;
                    line-height: 24px;
                }
                .chart-btn {
                    width: 120px;
                    height: 40px;

                    line-height: 40px;
                    box-sizing: border-box;
                    border: 1px solid #bd1a2d;
                    font-size: 18px;
                    color: #bd1a2d;
                    font-weight: 400;
                    margin: 0 auto 21px;
                    cursor: pointer;
                    a {
                        color: #bd1a2d;
                    }
                }
            }
        }

        .right-download-wrap {
            width: 316px;
            padding: 0 16px;
            margin-top: 24px;
            box-sizing: border-box;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            .com-title {
                height: 45px;
                line-height: 45px;
                border-bottom: 2px solid #dedede;
                display: flex;
                align-items: center;
                justify-content: space-between;
                div {
                    display: flex;
                    align-items: center;

                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 11px;
                        margin-left: 3px;
                    }
                    h5 {
                        height: 45px;
                        line-height: 45px;
                        font-size: 24px;
                        color: #000000;
                        border-bottom: 3px solid #bd1a2d;
                    }
                }

                ul {
                    display: flex;
                    li {
                        font-size: 18px;
                        margin-right: 18px;
                        cursor: pointer;
                        line-height: 36px;
                        color: #333;
                    }
                }
                span {
                    font-size: 14px;
                    color: #404040;
                    cursor: pointer;
                    img {
                        width: 4px;
                        height: 8px;
                        margin-left: 10px;
                    }
                }
            }
            ul.right-download {
                .first-download {
                    display: flex;
                    margin-top: 14px;
                    div {
                        img {
                            width: 118px;
                            height: 74px;
                            margin-right: 9px;
                            margin-bottom: 15px;
                        }
                        h5 {
                            width: 155px;
                            font-size: 16px;
                            color: #333333;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-bottom: 10px;
                        }
                        p {
                            width: 155px;
                            font-size: 14px;
                            color: #666666;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                .download-li {
                    width: 282px;
                    box-sizing: border-box;
                    line-height: 17px;
                    margin: 0 auto 18px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-left: 10px;
                    display: inline-block;
                    position: relative;
                    img {
                        width: 19px;
                        height: 19px;
                        margin-right: 4px;
                        vertical-align: middle;
                    }
                }
                .download-li::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #ffac13;
                    position: absolute;
                    left: 0;
                    top: 8px;
                }
            }
        }
    }
}
</style>
